import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import firebase from '../firebase';

// Material UI Components
import { makeStyles } from '@material-ui/core/styles';
import Paper from '@material-ui/core/Paper';
import Typography from '@material-ui/core/Typography';
import TextField from '@material-ui/core/TextField';
import Button from '@material-ui/core/Button';
import FormControl from '@material-ui/core/FormControl';
import InputLabel from '@material-ui/core/InputLabel';
import Select from '@material-ui/core/Select';

// Icons
import RemoveRoundedIcon from '@material-ui/icons/RemoveRounded';
import AddRoundedIcon from '@material-ui/icons/AddRounded';

const useStyles = makeStyles((theme) => ({
    paper: {
        maxWidth: 700,
        margin: 'auto',
        width: '100%',
        backgroundColor: 'var(--secondary-background)',
        padding: 20,
        marginBottom: 20,
    },
    flex: {
        display: 'flex',
        flexDirection: 'column',
        width: '100%',
    },
    subtitle: {
        color: 'var(--text-color)',
        textAlign: 'center',
        marginBottom: 10,
    },
    input: {
        marginBottom: 16,
    },
    button: {
        height: 50,  
        marginBottom: 16,
    },
    formControl: {
        marginBottom: 16,
    },
}));

function AddPoints(props) {
    const classes = useStyles();
    const { showSnackbar, leaderboard } = props;
    const [participants, setParticipants] = useState([]);
    const [participantName, setParticipantName] = useState('');
    const [amount, setAmount] = useState(0);
    const [pay, setPay] = useState(false);

    useEffect(() => {
        const db = firebase.firestore();
        db.collection('leaderboards').doc(leaderboard.id).collection('participants').onSnapshot((querySnapshot) => {
            let newParticipants = [];
            querySnapshot.forEach((doc) => newParticipants.push(doc));
            setParticipants(newParticipants);
            if (newParticipants.length > 0) {
                setParticipantName(newParticipants[0].data().name);
            }
        });
      }, [leaderboard.id]);

    const formSubmit = async (e) => {
        e.preventDefault();
        if (pay) {
            const db = firebase.firestore();
            let batch = db.batch();
            let fromDoc = db.collection('leaderboards').doc(leaderboard.id).collection('participants').doc(participantName);
            batch.update(fromDoc, {points: firebase.firestore.FieldValue.increment(-(amount * 100))});
            
            batch.commit().then(() => showSnackbar("Transaksjonen ble gjennomført"));
            firebase.analytics().logEvent('transaction', {
                amount: -amount
            });
        } else {
            const db = firebase.firestore();
            let batch = db.batch();
            let toDoc = db.collection('leaderboards').doc(leaderboard.id).collection('participants').doc(participantName);
            batch.update(toDoc, {points: firebase.firestore.FieldValue.increment(amount * 100)});
            
            batch.commit().then(() => showSnackbar("Transaksjonen ble gjennomført"));
            firebase.analytics().logEvent('transaction', {
                amount: amount
            });
        }
    }

    return (
        <Paper elevation={3} className={classes.paper}>
            <Typography variant="h5" className={classes.subtitle}>Legg til poeng</Typography>
            {participants.length > 0 ?
            <form className={classes.flex} autoComplete="off" onSubmit={formSubmit}>
                <Button
                    variant={!pay ? "outlined": "contained"}
                    color="secondary"
                    className={classes.button}
                    startIcon={<RemoveRoundedIcon />}
                    onClick={() => setPay(true)}
                >
                    Trekk fra poeng
                </Button>
                <Button
                    variant={pay ? "outlined": "contained"}
                    color="secondary"
                    className={classes.button}
                    startIcon={<AddRoundedIcon />}
                    onClick={() => setPay(false)}
                >
                    Legg til poeng
                </Button>
                <TextField type="number" onChange={(e) => setAmount(e.target.value)} value={amount} className={classes.input} label="Poeng" variant="outlined" required />
                <FormControl variant="outlined" className={classes.formControl}>
                    <InputLabel htmlFor="form-participant">Deltager</InputLabel>
                    <Select
                        native
                        value={participantName}
                        onChange={(e) => setParticipantName(e.target.value)}
                        label="Deltager"
                        inputProps={{
                            name: 'participant',
                            id: 'form-participant',
                        }}
                        >
                        {participants.map((participant) => 
                            <option key={participant.data().name} value={participant.data().name}>{participant.data().name}</option>
                        )}
                    </Select>
                </FormControl>
                <Button
                    variant="contained"
                    color="primary"
                    className={classes.button}
                    type='submit'
                    >
                    {pay ? "Trekk fra poeng" : "Legg til poeng"}
                </Button>
            </form>
            :
                <Typography variant="h6" className={classes.subtitle}>Legg til en deltager for å legge til poeng</Typography>
            }
        </Paper>
    );
}

AddPoints.propTypes = {
    showSnackbar: PropTypes.func.isRequired,
    leaderboard: PropTypes.object.isRequired,
};

export default AddPoints;