import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import firebase from '../firebase';

// Material UI Components
import { makeStyles } from '@material-ui/core/styles';
import Paper from '@material-ui/core/Paper';
import Typography from '@material-ui/core/Typography';
import Avatar from '@material-ui/core/Avatar';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemAvatar from '@material-ui/core/ListItemAvatar';
import ListItemText from '@material-ui/core/ListItemText';
import Divider from '@material-ui/core/Divider';

// Icons
import PersonRoundedIcon from '@material-ui/icons/PersonRounded';

const useStyles = makeStyles((theme) => ({
    paper: {
        maxWidth: 700,
        margin: 'auto',
        width: '100%',
        backgroundColor: 'var(--secondary-background)',
        padding: 20,
        marginBottom: 20,
    },
    subtitle: {
        color: 'var(--text-color)',
        textAlign: 'center',
        marginBottom: 10,
    },
    listText: {
        color: 'var(--text-color)',
    },
    listTextInner: {
        display: 'flex',
        justifyContent: 'space-between',
        fontWeight: 'bold',
    },
    primary: {
        color: theme.palette.primary.contrastText,
        backgroundColor: theme.palette.primary.main,
    },
}));

function LeaderboardTable(props) {
    const classes = useStyles();
    const { leaderboard } = props;
    const [participants, setParticipants] = useState([]);

    useEffect(() => {
        const db = firebase.firestore();
        db.collection('leaderboards').doc(leaderboard.id).collection('participants').onSnapshot((querySnapshot) => {
            let newParticipants = [];
            querySnapshot.forEach((doc) => newParticipants.push(doc));
            newParticipants.sort((a, b) => b.data().points - a.data().points);
            setParticipants(newParticipants);
        });
    }, [leaderboard.id]);

    const formatNumber = (x) => {
        return x.toString().replace(/\B(?<!\.\d*)(?=(\d{3})+(?!\d))/g, ".");
    }

    return (
        <Paper elevation={3} className={classes.paper}>
            <Typography variant="h5" className={classes.subtitle}>Ledertavle</Typography>
            <List>
                {participants.map((participant, index) => {
                    return (
                        <React.Fragment key={index}>
                            <ListItem>
                                <ListItemAvatar>
                                    <Avatar className={classes.primary}>
                                        <PersonRoundedIcon />
                                    </Avatar>
                                </ListItemAvatar>
                                <ListItemText className={classes.listText} primary={<div className={classes.listTextInner}><div>{participant.data().name}</div><div>{formatNumber(participant.data().points / 100)} poeng</div></div>} />
                            </ListItem>
                            {participants.length - 1 !== index && <Divider variant="inset" component="li" />}
                        </React.Fragment>
                    );
                })}
            </List>
        </Paper>
    );
}

LeaderboardTable.propTypes = {
    leaderboard: PropTypes.object.isRequired,
};

export default LeaderboardTable;