import firebase from 'firebase/app';
import 'firebase/firestore';
import 'firebase/auth';
import 'firebase/analytics';
const config = {
  apiKey: process.env.REACT_APP_API_KEY,
  authDomain: "ledertavle.ga",
  databaseURL: "https://ledertavle.firebaseio.com",
  projectId: "ledertavle",
  storageBucket: "ledertavle.appspot.com",
  messagingSenderId: "446048078348",
  appId: "1:446048078348:web:ee884721b2c64cea02bc29",
  measurementId: "G-0YJB3KL29Y"
};
if (!firebase.apps.length) {
  firebase.initializeApp(config);
  firebase.analytics();
}
export default firebase;