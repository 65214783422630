import React, { useState } from 'react';
import PropTypes from 'prop-types';
import firebase from '../firebase';

// Material UI Components
import { makeStyles } from '@material-ui/core/styles';
import Paper from '@material-ui/core/Paper';
import Typography from '@material-ui/core/Typography';
import TextField from '@material-ui/core/TextField';
import Button from '@material-ui/core/Button';

const useStyles = makeStyles((theme) => ({
    paper: {
        maxWidth: 700,
        margin: 'auto',
        width: '100%',
        backgroundColor: 'var(--secondary-background)',
        padding: 20,
        marginBottom: 20,
    },
    flex: {
        display: 'flex',
        flexDirection: 'column',
        width: '100%',
    },
    subtitle: {
        color: 'var(--text-color)',
        textAlign: 'center',
        marginBottom: 10,
    },
    input: {
        marginBottom: 16,
    },
    button: {
        height: 50,  
        marginBottom: 16,
    },
}));

function AddParticipant(props) {
    const classes = useStyles();
    const { showSnackbar, leaderboard } = props;
    const [name, setName] = useState('');

    const formSubmit = async (e) => {
        e.preventDefault();
        const db = firebase.firestore();
        db.collection('leaderboards').doc(leaderboard.id).collection('participants').doc(name).get().then((doc) => {
            if (!doc.exists) {
                db.collection('leaderboards').doc(leaderboard.id).collection('participants').doc(name).set({
                    name: name,
                    points: 0
                }).then(() => {
                    setName('');
                    showSnackbar(name + " ble lagt til");
                });
            } else {
                showSnackbar("Det eksisterer allerede en deltager med dette navnet");
            }
        })
    }

    return (
        <Paper elevation={3} className={classes.paper}>
            <Typography variant="h5" className={classes.subtitle}>Legg til deltager</Typography>
            <form className={classes.flex} autoComplete="off" onSubmit={formSubmit}>
                <TextField type="text" onChange={(e) => setName(e.target.value)} value={name} className={classes.input} label="Navn" variant="outlined" required />
                <Button
                    variant="contained"
                    color="primary"
                    className={classes.button}
                    type='submit'
                    >
                    Legg til deltager
                </Button>
            </form>
        </Paper>
    );
}

AddParticipant.propTypes = {
    showSnackbar: PropTypes.func.isRequired,
    leaderboard: PropTypes.object.isRequired,
};

export default AddParticipant;