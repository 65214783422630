import React, { useState, useEffect } from 'react';
import firebase from '../firebase';
import URLS from '../URLS';
import { useHistory } from 'react-router-dom';

// Material UI Components
import { makeStyles } from '@material-ui/core/styles';
import Paper from '@material-ui/core/Paper';
import Typography from '@material-ui/core/Typography';
import Avatar from '@material-ui/core/Avatar';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemAvatar from '@material-ui/core/ListItemAvatar';
import ListItemText from '@material-ui/core/ListItemText';

// Icons
import SportsEsportsRoundedIcon from '@material-ui/icons/SportsEsportsRounded';

const useStyles = makeStyles((theme) => ({
    paper: {
        maxWidth: 700,
        margin: 'auto',
        width: '100%',
        backgroundColor: 'var(--secondary-background)',
        padding: 20,
        marginBottom: 20,
    },
    subtitle: {
        color: 'var(--text-color)',
        textAlign: 'center',
        marginBottom: 10,
    },
    list: {
        maxHeight: 600,
        position: 'relative',
        overflow: 'auto',
    },
    listText: {
        color: 'var(--text-color)',
    },
}));

function LeaderboardsList(props) {
    const classes = useStyles();
    const [leaderboards, setLeaderboards] = useState([]);
    let history = useHistory();

    useEffect(() => {
        const db = firebase.firestore();
        const unsubscribe = db.collection('leaderboards').where("users", "array-contains", firebase.auth().currentUser.uid)
          .onSnapshot(snapshot => {
            if (snapshot.size) {
                let userLeaderboards = [];
                snapshot.forEach((doc) => {
                    userLeaderboards.push(doc);
                });
                setLeaderboards(userLeaderboards);
            }
          })
      return () => {
          unsubscribe()
        }
      }, [])

    const openLeaderboard = (id) => {
        history.push(URLS.admin.concat(id).concat('/'));
    }

    return (
        <Paper elevation={3} className={classes.paper}>
            <Typography variant="h5" className={classes.subtitle}>Mine ledertavler</Typography>
            <List className={classes.list}>
                {leaderboards.map((leaderboard) => {
                    return (
                        <ListItem button key={leaderboard.id} onClick={() => openLeaderboard(leaderboard.id)} >
                            <ListItemAvatar>
                                <Avatar className={classes.primary}>
                                    <SportsEsportsRoundedIcon />
                                </Avatar>
                            </ListItemAvatar>
                            <ListItemText className={classes.listText} primary={leaderboard.data().name} />
                        </ListItem>
                    );
                })}
            </List>
        </Paper>
    );
}

export default LeaderboardsList;